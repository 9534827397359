import { FormControl, MenuItem, Select, InputLabel } from "@material-ui/core";
import React, { useState } from "react";

export default ({
  variableDialog,
  setvariableDialog,
  index,
  databaseObjectModels,
}) => {
  // const objectModels = [
  //   "Personne physique",
  //   "Personne morale",
  //   "Personne physique et personne morale",
  //   "Tribunal",
  //   "CPAM",
  //   // "SAS",
  //   // "SARL",
  //   // "SCI",
  //   // "Association",
  // ];
  const objectModels = databaseObjectModels.map((model) => model.definitionJson.name);

  return (
    <FormControl
      size="small"
      style={{ width: 300, backgroundColor: "white" }}
      variant="outlined"
    >
      <InputLabel>Modèle d'objet de la base de données</InputLabel>
      <Select
        label="Modèle d'objet"
        value={
          variableDialog.database_object_model_fields_mapping[index]
            ?.database_object_model_name
        }
        onChange={(e) => {
          let result = { ...variableDialog };
          result.database_object_model_fields_mapping[
            index
          ].database_object_model_name = e.target.value;
          result.database_object_model_name = e.target.value;
          setvariableDialog(result);
        }}
      >
        <MenuItem keu="0" value="">
          <em>Aucun</em>
        </MenuItem>
        {objectModels.map((elem, key) => (
          <MenuItem key={key + 1} value={elem}>
            {elem}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
