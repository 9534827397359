import DocumentContext from "../../../providers/DocumentContext";
import getNameType from "../EditorView/functions/getNameType";
import { Button } from "@material-ui/core/";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import TemplateLib from "~/common/libs/interview-template-lib";
import checkOrder from "./checkOrder";

const useStyles = makeStyles(({ palette, spacing }) => ({
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 20px",
    fontSize: 16,
    borderBottom: " 1px solid silver",
  },
  button: {
    width: 25,
    height: 25,
    minHeight: 25,
    marginLeft: 10,
  },
  table: {
    padding: 0,
    margin: "15px 0px",
    border: "1px solid grey",
    borderRadius: 5,
    backgroundColor: "white",
  },
  tableHead: {
    fontSize: 12,
    padding: 5,
    color: "black",
    borderBottom: " 0.2px solid silver",
  },
  tableCellId: {
    fontSize: 10,
    padding: 5,
    color: "grey",
  },
  tableCell: {
    fontSize: 10,
    padding: 5,
  },
  row: {
    cursor: "pointer",
    transition: "0.1s",
    "&:hover": {
      backgroundColor: "#ddd!important",
    },
  },
  badge: {
    color: "white",
    fontWeight: "bold",
    padding: "5px 15px",
    borderRadius: 5,
    textShadow: "grey 1.4px 0.4px",
  },
  buttonOrder: {
    width: 30,
    height: 20,
    boxShadow: "none",
    border: "1px solid silver",
    borderRadius: 0,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#ccc",
    },
  },
}));

export default function List({
  list,
  setvariableDialog,
  group,
  isOrderButton,
  isUnsuedGlobalVariable,
  isUnsuedLocalVariable,
  setUnusedGlobalVariables,
  setUnusedLocalVariables,
}) {
  const classes = useStyles();
  const { document, updateDocument } = useContext(DocumentContext);
  const [disableOrderButtons, setdisableOrderButtons] = useState(false);

  const changeOrder = ({ oldOrder, orderUse }) => {
    setdisableOrderButtons(true);
    let result = { ...document };
    const indexVar = result.variables.findIndex(
      (el) => el.group === group && el.order_in_group === oldOrder
    );
    const indexVar_2 = result.variables.findIndex(
      (el) => el.group === group && el.order_in_group === oldOrder + orderUse
    );
    TemplateLib.updateVariable(result.variables[indexVar].id, {
      ...result.variables[indexVar],
      order_in_group: oldOrder + orderUse,
    })
      .then(() => {
        TemplateLib.updateVariable(result.variables[indexVar_2].id, {
          ...result.variables[indexVar_2],
          order_in_group: oldOrder,
        })
          .then(() => {
            result.variables[indexVar].order_in_group = oldOrder + orderUse;
            result.variables[indexVar_2].order_in_group = oldOrder;
            updateDocument(result);
            toast.success("Ordre changé");
          })
          .catch(() => toast.error("Erreur dans le changement d'ordre"));
      })
      .catch(() => toast.error("Erreur dans le changement d'ordre"));
    setdisableOrderButtons(false);
  };

  const findField = (node, fieldId) => {
    if (node && node.fields) {
      for (let field of node.fields) {
        if (field.id == fieldId) {
          return field
        }
      }
    }
    // Recurse into each child
    if (node && node.children) {
      for (let child of node.children) {
        let foundField = findField(child, fieldId)
        if (foundField) return foundField
      };
    }
    return null
  };

  const deleteMapping = async (variable) => {
    TemplateLib.getObjectModels()
      .then(res => {
        let databaseObjectModels = res.data.results
        for (const mappings of variable.database_object_model_fields_mapping) {
          if (mappings === null || mappings.database_object_model_name === '') { continue }
          let model = databaseObjectModels.find(model => model.definitionJson.name === mappings.database_object_model_name)
          console.log('Updating DP model', model.definitionJson.name)
          mappings.data.forEach(data => {
            if (!data.database_object_model_field_id) return
            let modelField = findField(model.definitionJson, data.database_object_model_field_id)
            console.log('found modelField', modelField)

            if (modelField !== undefined) {
              modelField.existingMapping = modelField.existingMapping.filter(m => m.variableId !== variable.id)
              console.log('updating existingMapping', modelField.existingMapping)
            }
          })
          TemplateLib.updateObjectModels(model)
        }
      })
      .catch(e => {
        console.error("Failed to fetch database object models:", e)
      })
  }

  const deleteVariable = async (row) => {
    if (
      window.confirm(
        "Voulez vous vraiment supprimer cette variable :" + row.name
      )
    ) {
      const copy = { ...document };
        try {
          // Remove the DatabaseObjectModel mapping
          await deleteMapping(row);
        }
        catch (e) {
          console.error("Error deleting mapping", e);
          toast.error("Erreur durant la suppression de la variable");
          return;
        }
        if (isUnsuedGlobalVariable && !isUnsuedLocalVariable) {
          TemplateLib.deleteVariable(row.id).then(() => {
            setUnusedGlobalVariables(list.filter((el) => el.id !== row.id));
            const isExists = copy.variables.find((variable) => {
              return variable.id === row.id;
            });
            if (isExists) {
              deleteAndReorderVariables(row);
            }
            toast.success("Suppression de la variable enregistrée.");
          });
        }
        if (!isUnsuedGlobalVariable && isUnsuedLocalVariable) {
          TemplateLib.deleteVariable(row.id).then(() => {
            setUnusedLocalVariables(list.filter((el) => el.id !== row.id));
            const isExists = copy.variables.find((variable) => {
              return variable.id === row.id;
            });
            if (isExists) {
              deleteAndReorderVariables(row);
            }
            toast.success("Suppression de la variable enregistrée.");
          });
        }
        if (!isUnsuedGlobalVariable && !isUnsuedLocalVariable) {
          TemplateLib.deleteVariable(row.id).then(() => {
            deleteAndReorderVariables(row);
            TemplateLib.getUnusedGlobalVariable(document.project).then((result) => {
              setUnusedGlobalVariables(result);
            });
            TemplateLib.getUnusedLocalVariable(document.project).then((result) => {
              setUnusedLocalVariables(result);
            });
            toast.success("Suppression de la variable enregistrée.");
          });
        }
      }
  };

  const deleteAndReorderVariables = (row) => {
    const copy = { ...document };
    copy.variables = copy.variables.filter((el) => el.id !== row.id);
    const vars = copy.variables.filter((el) => el.group === row.group);
    checkOrder({ toOrder: vars, isGroup: false }).then((orderedVariables) => {
      orderedVariables.map((orderedVar) => {
        const myVar = copy.variables.find((resultVariable) => {
          return orderedVar.id === resultVariable.id;
        });
        copy.variables[copy.variables.indexOf(myVar)] = orderedVar;
      });
      updateDocument(copy);
    });
  };

  return (
    <div style={{ margin: "15px 10px" }}>
      <TableContainer className={classes.table}>
        <Table stickyHeader style={{ padding: 0, border: "1px solid silver" }}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead}></TableCell>
              <TableCell className={classes.tableHead} align="center">
                ID
              </TableCell>
              <TableCell className={classes.tableHead} align="center">
                Nom
              </TableCell>
              <TableCell className={classes.tableHead} align="center">
                Type
              </TableCell>

              <TableCell className={classes.tableHead} align="center">
                Globale
              </TableCell>
              <TableCell
                className={classes.tableHead}
                align="center"
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ maxHeight: 300 }}>
            {list
              .sort(function (a, b) {
                return a.order_in_group - b.order_in_group;
              })
              .map((row, key) => (
                <TableRow
                  key={key}
                  className={classes.row}
                  onClick={(e) => {
                    if (
                      e.target.closest(".buttonOrder") === null &&
                      e.target.closest(".deleteButton") === null
                    )
                      setvariableDialog(row);
                  }}
                >
                  <TableCell
                    className={classes.tableCell}
                    style={{ width: 40 }}
                    align="center"
                  >
                    {row.group && (
                      <div
                        className={"buttonOrder"}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight: 10,
                        }}
                      >
                        {isOrderButton && (
                          <Button
                            className={classes.buttonOrder}
                            onClick={() =>
                              changeOrder({
                                oldOrder: row.order_in_group,
                                orderUse: -1,
                              })
                            }
                            disabled={
                              row.order_in_group === 1 || disableOrderButtons
                            }
                          >
                            <ArrowDropUpIcon />
                          </Button>
                        )}
                        {isOrderButton && (
                          <Button
                            className={classes.buttonOrder}
                            onClick={() =>
                              changeOrder({
                                oldOrder: row.order_in_group,
                                orderUse: 1,
                              })
                            }
                            disabled={
                              row.order_in_group === list.length ||
                              disableOrderButtons
                            }
                          >
                            <ArrowDropDownIcon />
                          </Button>
                        )}
                      </div>
                    )}
                  </TableCell>
                  <TableCell className={classes.tableCellId} align="center">
                    #{row.id}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    align="center"
                    style={{ fontSize: 14 }}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    {getNameType(row.type)}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    {row.is_global && (
                      <CheckCircleIcon
                        color="primary"
                        style={{ fontSize: 18 }}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " deleteButton"}
                    align="center"
                    onClick={() => deleteVariable(row)}
                  >
                    <DeleteForeverIcon
                      className="deleteButton"
                      style={{
                        fontSize: 18,
                        color: "#f93b3b",
                        cursor: "pointer",
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
