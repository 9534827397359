import {
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  Grid,
  InputLabel,
  TextField,
  Button,
  IconButton,
  Divider,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import { useEffect, useState } from "react";

import SelectObjectModel from "../SelectObjectModel";
import RolesInput from "../RolesInput";
import MappingComplexInput from "../MappingComplexInput";
import MappingComplexInputOld from "../MappingComplexInputOld";

export default ({
  variableDialog,
  setvariableDialog,
  setoriginalvariableType,
  originalvariableType,
  databaseObjectModels,
}) => {
  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  const [databaseObjectModelName, setDatabaseObjectModelName] = useState("");

  useEffect(() => {
    const id = makeid(8);
    if (originalvariableType !== "array") {
      setvariableDialog({
        ...variableDialog,
        options: [{ id, label: "", type: "text" }],
        placeholder: [[{ id, value: "" }]],
      });
      setoriginalvariableType("array");
    }
  }, []);

  return (
    originalvariableType === "array" && (
      <>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{
            backgroundColor: "#eee",
            border: "1px solid silver",
          }}
        >
          <Checkbox
            checked={variableDialog.is_mail_merging}
            onChange={(e) => {
              let result = { ...variableDialog };
              result.is_mail_merging = e.target.checked;
              setvariableDialog(result);
            }}
          ></Checkbox>
          <span>Variable de publipostage</span>

          <Divider
            style={{
              marginTop: 10,
              marginBottom: 10,
            }}
          />

          <SelectObjectModel
            variableDialog={variableDialog}
            setvariableDialog={setvariableDialog}
            index={0}
            databaseObjectModels={databaseObjectModels}
          ></SelectObjectModel>
          <FormControl
            size="small"
            style={{
              width: 300,
              backgroundColor: "white",
              marginLeft: 15,
              marginRight: 15,
            }}
            variant="outlined"
          >
            <InputLabel>Type de mapping</InputLabel>
            <Select
              label="Type de mapping"
              value={
                variableDialog.database_object_model_fields_mapping[0]
                  .mappingType
              }
              onChange={(e) => {
                let result = { ...variableDialog };
                result.database_object_model_fields_mapping[0].mappingType =
                  e.target.value;
                setvariableDialog(result);
              }}
            >
              <MenuItem value={"simple"}>Mono-sélection</MenuItem>
              {/* <MenuItem value={"address"}>Adresse</MenuItem> */}
              <MenuItem value={"list_with_single_choice"}>
                Mono-sélection dans une liste
              </MenuItem>
              <MenuItem value={"list_with_multiple_choices"}>
                Multi-sélection dans une liste
              </MenuItem>
            </Select>
          </FormControl>

          <RolesInput
            variableDialog={variableDialog}
            setvariableDialog={setvariableDialog}
          ></RolesInput>
          <Divider
            style={{
              marginTop: 10,
              marginBottom: 10,
            }}
          />
          <div style={{ margin: 15 }}>
            Le client remplira autant d'objet qu'il le souhaitera
          </div>
          {variableDialog.options &&
            variableDialog.options?.map((el, key) => (
              <div
                key={key}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 5,
                }}
              >
                {/* Buttons to reorder elements */}
                <div style={{ flex: "1" }}>
                  {key > 0 && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        let result = { ...variableDialog };
                        let temp = result.options[key];
                        result.options[key] = result.options[key - 1];
                        result.options[key - 1] = temp;
                        let temp2 = result.placeholder[0][key];
                        result.placeholder[0][key] =
                          result.placeholder[0][key - 1];
                        result.placeholder[0][key - 1] = temp2;
                        setvariableDialog(result);
                      }}
                    >
                      <KeyboardArrowUpIcon />
                    </IconButton>
                  )}

                  {key < variableDialog.options.length - 1 && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        let result = { ...variableDialog };
                        let temp = result.options[key];
                        result.options[key] = result.options[key + 1];
                        result.options[key + 1] = temp;
                        let temp2 = result.placeholder[0][key];
                        result.placeholder[0][key] =
                          result.placeholder[0][key + 1];
                        result.placeholder[0][key + 1] = temp2;
                        setvariableDialog(result);
                      }}
                    >
                      <KeyboardArrowDownIcon />
                    </IconButton>
                  )}
                </div>

                <TextField
                  style={{
                    backgroundColor: "white",
                    marginLeft: 10,
                    marginRight: 10,
                    width: 400,
                  }}
                  label={"Element " + (key + 1)}
                  size="small"
                  fullWidth
                  variant="outlined"
                  type="text"
                  value={variableDialog.options[key].label}
                  onChange={(e) => {
                    let result = { ...variableDialog };
                    let fieldMapping = variableDialog.database_object_model_fields_mapping[0].data
                      .find((fm) => fm.variable_field_name == variableDialog.options[key].label)
                    if (fieldMapping) fieldMapping.variable_field_name = e.target.value
                    result.options[key].label = e.target.value;
                    setvariableDialog(result);
                  }}
                />

                {/* <SelectObjectModelField
                  variableDialog={variableDialog}
                  setvariableDialog={setvariableDialog}
                  elementKey={key}
                  tabValue={1}
                ></SelectObjectModelField> */}
                <MappingComplexInputOld
                  style={{
                    backgroundColor: "white",
                    width: "100%",
                  }}
                  variableFieldName={variableDialog.options[key].label}
                  variableFieldKey={key}
                  objectNumber={0}
                  variableDialog={variableDialog}
                  setvariableDialog={setvariableDialog}
                ></MappingComplexInputOld>

                <MappingComplexInput
                  style={{
                    backgroundColor: "white",
                    width: "100%",
                  }}
                  variableFieldName={variableDialog.options[key].label}
                  variableFieldKey={key}
                  objectNumber={0}
                  variableDialog={variableDialog}
                  setvariableDialog={setvariableDialog}
                  databaseObjectModels={databaseObjectModels}
                ></MappingComplexInput>

                <FormControl
                  size="small"
                  style={{
                    width: 200,
                    backgroundColor: "white",
                    marginLeft: 10,
                  }}
                  variant="outlined"
                >
                  <InputLabel>Type</InputLabel>
                  <Select
                    label="Type"
                    value={variableDialog.options[key].type}
                    onChange={(e) => {
                      let result = { ...variableDialog };
                      result.options[key].type = e.target.value;
                      setvariableDialog(result);
                    }}
                  >
                    <MenuItem value={"text"}>Texte</MenuItem>
                    <MenuItem value={"number"}>Nombre</MenuItem>
                    <MenuItem value={"bool"}>Oui / Non</MenuItem>
                    <MenuItem value={"date"}>Date</MenuItem>
                  </Select>
                </FormControl>
                {el.type === "bool" ? (
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: 10,
                      width: 300,
                    }}
                    onClick={() => {
                      let result = { ...variableDialog };
                      result.placeholder[0][key].value =
                        !result.placeholder[0][key].value;
                      setvariableDialog(result);
                    }}
                  >
                    Cochée par défaut
                    <Checkbox
                      checked={variableDialog.placeholder[0][key].value}
                    />
                  </div>
                ) : (
                  <TextField
                    label="Valeur pré-remplie"
                    value={variableDialog.placeholder[0][key].value}
                    onChange={(e) => {
                      let result = { ...variableDialog };
                      result.placeholder[0][key].value =
                        el.type === "number" ? +e.target.value : e.target.value;
                      setvariableDialog(result);
                    }}
                    type={el.type}
                    variant="outlined"
                    size="small"
                    style={{
                      marginLeft: 10,
                      backgroundColor: "white",
                      width: 300,
                    }}
                  />
                )}
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  disabled={key === 0}
                  style={{ marginLeft: 15 }}
                  onClick={() => {
                    let result = { ...variableDialog };
                    result.options = result.options.filter(
                      (option) => option.id !== el.id
                    );
                    result.placeholder[0] = result.placeholder[0].filter(
                      (option) => option.id !== el.id
                    );
                    result.database_object_model_fields_mapping[0].data =
                      result.database_object_model_fields_mapping[0].data.filter(
                        (option) => option.variable_field_name !== el.label
                      );
                    setvariableDialog(result);
                  }}
                >
                  <RemoveIcon />
                </Button>
              </div>
            ))}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              paddingBottom: 10,
              paddingTop: 20,
            }}
          >
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                let result = { ...variableDialog };
                const id = makeid(8);
                result.options.push({ id, label: "", type: "text" });
                result.placeholder[0].push({ id, value: "" });
                result.database_object_model_fields_mapping[0].data.push({
                  mapping_complex: "",
                  database_object_model_field_name: "",
                  variable_field_name: "",
                });
                setvariableDialog(result);
              }}
            >
              <AddIcon style={{ fontSize: 18, marginRight: 10 }} /> Ajouter
              élément
            </Button>
          </div>
        </Grid>
      </>
    )
  );
};
