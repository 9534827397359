import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ListSubheader,
} from "@material-ui/core"

export default ({
  variableDialog,
  setvariableDialog,
  type,
  variableFieldName,
  variableFieldKey,
  objectNumber,
  databaseObjectModels,
}) => {

  const currentEntity = variableDialog.database_object_model_fields_mapping[objectNumber].database_object_model_name
  const model = databaseObjectModels.find(model => model.definitionJson.name === currentEntity)
  console.log('currentEntity', currentEntity, 'model', model)
  const options = getOptions()
  const selectedValue = options.find(
    (option) => option.id === getMapping(variableDialog, variableFieldName)?.id
  ) || ""; // Fallback to "" if no match is found

  function getOptions() {
    if (!model) return []

    const getAllFields = (node, path = '') => {
      let fieldsList = [];
      // Append fields of the current level
      node.fields.forEach(field => {
        if (field.type == 'array') {
          let fieldColumns = field.columns.filter(column => column.type != 'file')
            .flatMap((column) => {
              if (column.type == 'group_field') {
                let subfields = column.fields.filter(subfield => subfield.type != 'file')
                  .map((subfield) => {
                    return {
                      'id': subfield.id,
                      'name': `${path ? path + '/' : ''}${field.name}/${column.name}/${subfield.name}`
                    }
                  });
                return subfields
              }
              return {
                'id': column.id,
                'name': `${path ? path + '/' : ''}${field.name}/${column.name}`
              }
            });
          fieldsList.push(...fieldColumns);
        } else if (field.type == 'group_field') {
          let subfields = field.fields.filter(subfield => subfield.type != 'file')
            .map((subfield) => {
              return {
                'id': subfield.id,
                'name': `${path ? path + '/' : ''}${field.name}/${subfield.name}`
              }
            });
          fieldsList.push(...subfields);
        } else {
          fieldsList.push({
            'id': field.id,
            'name': `${path ? path + '/' : ''}${field.name}`
          });
        }
      });
      // Recurse into each child
      node.children.forEach(child => {
        const childPath = path ? `${path}/${child.name}` : child.name;
        fieldsList = fieldsList.concat(getAllFields(child, childPath));
      });
      return fieldsList;
    };

    const options = getAllFields(model.definitionJson);
    console.log('options', options);
    return options
  }


  function getMapping(variableDialog, variableFieldName) {
    try {
      // console.log("getMapping", variableDialog);
      let fieldMappings =
        variableDialog.database_object_model_fields_mapping[objectNumber].data

      if (fieldMappings == undefined) {
        variableDialog.database_object_model_fields_mapping[objectNumber].data =
          []
        fieldMappings = []
      }

      let fieldMapping = fieldMappings.find(
        (fm) => fm.variable_field_name == variableFieldName
      )
      if (fieldMapping === undefined) return ""

      return {'id': fieldMapping.database_object_model_field_id, 'name': fieldMapping.database_object_model_field_name}
    } catch (error) {
      console.log(error)
      return ""
    }
  }

  function handleChange(e) {
    const selectedOption = e.target.value;
    let result = { ...variableDialog }
    let fieldMappings = result.database_object_model_fields_mapping[objectNumber].data
    let fieldMapping = fieldMappings.find((fm) => fm.variable_field_name == variableFieldName)
    if (fieldMapping === undefined) {
      fieldMapping = {
        variable_field_name: variableFieldName,
        database_object_model_field_name: "",
      }
      fieldMappings.push(fieldMapping)
    }
    // fieldMapping.database_object_model_field_name = selectedOption.name
    fieldMapping.database_object_model_field_id = selectedOption.id
    setvariableDialog(result)
  }

  return (
    <FormControl
      size="small"
      variant="outlined"
      style={{
        backgroundColor: "white",
        width: "100%",
        maxWidth: 300,
      }}
    >
      <InputLabel>Mapping V2</InputLabel>
      <Select
        type={type}
        style={{
          backgroundColor: "white",
          width: "100%",
          maxWidth: 300,
        }}
        size="small"
        label="Mapping V2"
        value={selectedValue}
        getOptionSelected={(option, value) => option.id === value.id}
        renderValue={(selected) => selected.name}
        onChange={handleChange}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option.name}
          </MenuItem>
        ))}

      </Select>
    </FormControl>
  )
}
